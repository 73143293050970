import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div className="container">
      <h1>404: cette page n'existe pas !</h1>
      <p>Ce lien de page nous est inconnu. Vérifiez le lien ou retournez sur la homepage.</p>
    </div>
  </Layout>
)

export default NotFoundPage
